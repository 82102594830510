@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .logoLabel,
    & .caret {
      cursor: pointer;

      /* Same as active */
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--colorWhite);
      box-shadow: var(--boxShadowPopup);
    }
  }
}

.logoLabel {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;

}
.noPriceSetLabel {
  width: 30px;
  height: 30px;
}

.mapLabelActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  transition: var(--transitionStyleButton);

  /* Caret should have same bg-color and border as label */
  background-color: var(--colorWhite);
  border-right-style: solid;
  border-right-color: var(--colorGrey100);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--colorGrey100);
  border-bottom-width: 1px;
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}
